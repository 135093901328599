import React from "react";
import img from "../images/about.jpg";
const About = () => (
  <section className="about" id="about">
    <div className="content">
      <div className="title">
        <span>Über mich</span>
      </div>
      <div className="about-details">
        <div className="left">
          <img src={img} alt="" />
        </div>
        <div className="right">
          <div className="topic">Nour</div>
          <p>
            Ich bin Mohammad Nour Gaser, ein engagierter Informatikstudent im 5.
            Semester an der Hochschule Worms, mit einer großen Leidenschaft für
            Full-Stack-Entwicklung und IT-Service-Management. Derzeit suche ich
            Praktika und berufliche Möglichkeiten, um meine Fähigkeiten zu
            erweitern und kreative Lösungen zu entwickeln. Erfahrung
            Informatikstudent an der Hochschule Worms (03/2022 – Gegenwart)
            Spezialisiert auf angewandte Informatik mit Schwerpunkt Software
            Engineering. Webentwickler-Praktikant bei Creativ GmbH, Landau
            (06/2021 – 10/2021) Entwicklung von Webanwendungen mit HTML, CSS,
            JavaScript und PHP. Zusammenarbeit mit dem Designteam zur Erstellung
            benutzerfreundlicher Schnittstellen. IT-Service-Praktikant bei DBR
            David + Baader GmbH, Rülzheim (08/2017 – 08/2018) Lösung technischer
            Probleme und Installation neuer Hardware und Software. Verwaltung
            von Anfragen im Ticket-System.
          </p>
          <div className="button">
            <a href="#Projects">
              <button>Meine Projekte</button>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default About;
