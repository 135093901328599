import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import {
  faFacebookF,
  faGithub,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import LOGOW from "../images/logoW.png";
import LOGO from "../images/logo.png";

const Navigation = () => {
  const navRef = useRef(null);
  const scrollBtnRef = useRef(null);
  const navBarRef = useRef(null);
  const menuBtnRef = useRef(null);
  const cancelBtnRef = useRef(null);
  const bodyRef = useRef(document.body);
  const [LogoImg, setLogoImg] = useState(LOGO);

  useEffect(() => {
    const handleScroll = () => {
      if (document.documentElement.scrollTop > 20) {
        navRef.current.classList.add("sticky");
        scrollBtnRef.current.style.display = "block";
        setLogoImg(LOGOW);
      } else {
        navRef.current.classList.remove("sticky");
        scrollBtnRef.current.style.display = "none";
        setLogoImg(LOGO);
      }
    };

    const handleMenuBtnClick = () => {
      navBarRef.current.classList.add("active");
      menuBtnRef.current.style.opacity = "0";
      menuBtnRef.current.style.pointerEvents = "none";
      bodyRef.current.style.overflow = "hidden";
      scrollBtnRef.current.style.pointerEvents = "none";
    };

    const handleCancelBtnClick = () => {
      navBarRef.current.classList.remove("active");
      menuBtnRef.current.style.opacity = "1";
      menuBtnRef.current.style.pointerEvents = "auto";
      bodyRef.current.style.overflow = "auto";
      scrollBtnRef.current.style.pointerEvents = "auto";
    };

    const handleNavLinkClick = () => {
      navBarRef.current.classList.remove("active");
      menuBtnRef.current.style.opacity = "1";
      menuBtnRef.current.style.pointerEvents = "auto";
    };

    window.addEventListener("scroll", handleScroll);
    menuBtnRef.current.addEventListener("click", handleMenuBtnClick);
    cancelBtnRef.current.addEventListener("click", handleCancelBtnClick);

    const navLinks = navBarRef.current.querySelectorAll(".menu li a");
    navLinks.forEach((link) =>
      link.addEventListener("click", handleNavLinkClick)
    );

    return () => {
      window.removeEventListener("scroll", handleScroll);
      menuBtnRef.current.removeEventListener("click", handleMenuBtnClick);
      cancelBtnRef.current.removeEventListener("click", handleCancelBtnClick);
      navLinks.forEach((link) =>
        link.removeEventListener("click", handleNavLinkClick)
      );
    };
  }, []);

  return (
    <div>
      <div className="scroll-button">
        <a href="#home" ref={scrollBtnRef}>
          <FontAwesomeIcon icon={faArrowUp} />
        </a>
      </div>
      <nav ref={navRef}>
        <div className="flex-box-nav">
          <div className="navbar" ref={navBarRef}>
            <div className="logo">
              <a href="#">
                <img src={LogoImg} alt="" id="logoNour" />
              </a>
            </div>
            <ul className="menu">
              <li>
                <a href="#home">Home</a>
              </li>
              <li>
                <a href="#about">About</a>
              </li>
              <li>
                <a href="#contact">Contact</a>
              </li>
              <li>
                <a href="#Projects">Projects</a>
              </li>
              <div className="cancel-btn" ref={cancelBtnRef}>
                <FontAwesomeIcon icon={faTimes} />
              </div>
            </ul>
            <div className="media-icons">
              <a href="https://www.facebook.com/XOXR0">
                <FontAwesomeIcon icon={faFacebookF} />
              </a>
              <a href="https://github.com/nour022">
                <FontAwesomeIcon icon={faGithub} />
              </a>
              <a href="https://www.instagram.com/_xo_xr_/">
                <FontAwesomeIcon icon={faInstagram} />
              </a>
            </div>
          </div>
          <div className="menu-btn" ref={menuBtnRef}>
            <FontAwesomeIcon icon={faBars} />
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navigation;
