import React from "react";

const Home = () => (
  <section className="home" id="home">
    <div className="home-content">
      <div className="text">
        <div className="text-one">Hello,</div>
        <div className="text-two">ich bin Nour</div>
        <div className="text-three">Student und Webentwickler</div>
        <div className="text-four">aus Deutschland</div>
      </div>
      <div className="button">
        <a href="#Projects">
          <button>Hier sind meine Projekte</button>
        </a>
      </div>
    </div>
  </section>
);

export default Home;
