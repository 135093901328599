import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDesktop,
  faPaintBrush,
  faChartLine,
  faCameraRetro,
  faTabletAlt,
  faWebAwesome,
  faRobot,
  faSchool,
} from "@fortawesome/free-solid-svg-icons";
import {
  faAndroid,
  faAppStore,
  faWebflow,
} from "@fortawesome/free-brands-svg-icons";
import imgp1 from "../images/Flutter2.png";
import imgp2 from "../images/Screenshot 2024-08-05 200300.png";
import imgp3 from "../images/ikz5.png";
import imgp4 from "../images/exp30.png";
import imgp5 from "../images/iti.png";
import imgp6 from "../images/ml.png";

const projects = [
  {
    img: imgp2,
    icon: faAppStore,
    topic: "Tkinter Online shop",
    description:
      "Entwicklung einer GUI-Anwendung zur Verwaltung eines Online-Shops unter Verwendung der Tkinter-Bibliothek in Python.",
    languages: ["Python", "Tkinter"],
    link: "https://github.com/nour022/Uni_onlineShop_python",
  },
  {
    img: imgp6,
    icon: faRobot,
    topic: "Beginner Project in Machine Learning",
    description:
      "Durchführung eines Machine Learning Projekts mit Python-Bibliotheken.",
    languages: ["Python", "Scikit-learn"],
    link: "https://github.com/nour022/Machin_Learning",
  },
  {
    img: imgp1,
    icon: faAndroid,
    topic: "TutorFinderApp",
    description:
      "Entwicklung einer mobilen Anwendung zur Vermittlung von Nachhilfelehrern und -schülern.",
    languages: ["Flutter", "Dart", "Firebase"],
    link: "https://github.com/Amer222323/TutorFinderApp_flutter ",
  },
  {
    img: imgp3,
    icon: faWebAwesome,
    topic: "Website des Kulturzentrums Witten e.V.",
    description:
      "Entwicklung einer benutzerfreundlichen Website für das Kulturzentrum Witten e.V., um deren Aktivitäten und Angebote online zu präsentieren.",
    languages: ["Laravel 8", "CSS", "HTML", "SQL", "js"],
    link: "https://ikz-witten.de/",
  },
  {
    img: imgp5,
    icon: faWebflow,
    topic: "information technology institute.oi",
    description:
      "Die Webseite des 'Information Technology' Institute bietet IT-Schulungsprogramme, Nachrichten und Partnerinformationen. Sie ist modern gestaltet mit einer klaren Navigation für Programme und News.",
    languages: ["CSS", "HTML", "js"],
    link: "https://nour022.github.io/nour022-information-technology-institute.oi/",
  },
  {
    img: imgp4,
    icon: faSchool,
    topic: "WebProjekts and Exercises",
    description:
      "Das ist eine Sammlung meiner Projekte und Übungen, die ich in meiner Freizeit und während der Uni-Zeit erstellt und entwickelt habe.",
    languages: ["PHP", "CSS", "HTML", "React", "js"],
    link: "https://nour022.github.io/WebProjekts_and_Exercises/",
  },
];

const Projects = () => (
  <section className="Projects" id="Projects">
    <div className="content">
      <div className="title">
        <span>Meine Projekte</span>
      </div>
      <div className="boxes">
        {projects.map((project, index) => (
          <div className="box" key={index}>
            <div className="img-box">
              <img src={project.img} alt={project.topic} />
            </div>
            <div className="icon">
              <FontAwesomeIcon icon={project.icon} />
            </div>
            <div className="topic">{project.topic}</div>
            <p>{project.description}</p>
            <div className="languages">
              {project.languages.map((language, idx) => (
                <div className="wAuto" key={idx}>
                  {language}
                </div>
              ))}
            </div>
            <div className="button btn-pro">
              <a href={project.link} target="_blank">
                <button>DEMO||LIVE</button>
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  </section>
);

export default Projects;
