import React from "react";
import "./App.css";
import Navigation from "./components/Navigation";
import Home from "./components/Home";
import About from "./components/About";
import Contact from "./components/Contact";
import Projects from "./components/Projects";

function App() {
  return (
    <div>
      <Navigation />
      <Home />
      <About />
      <Contact />
      <Projects />
      <footer>
        <div className="text">
          <span>
            Created By <a href="https://github.com/nour022">Nour</a> | &#169;
            2024 All Rights Reserved .
          </span>
        </div>
      </footer>
    </div>
  );
}

export default App;
