import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faGithub,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";

const Contact = () => (
  <section className="contact" id="contact">
    <div className="content">
      <div className="title">
        <span>Kontaktiere mich</span>
      </div>
      <div className="text">
        <div className="topic">Haben Sie ein Projekt?</div>
        <p>
          Ich bin immer begeistert, neue Herausforderungen anzunehmen und an
          innovativen Projekten zu arbeiten. Egal, ob Sie ein konkretes Projekt
          im Sinn haben oder einfach nur mögliche Möglichkeiten besprechen
          möchten, zögern Sie nicht, mich zu kontaktieren. Lassen Sie uns
          verbinden und gemeinsam etwas Großartiges schaffen!
        </p>
        <div className="cont-iconsBox">
          <div className="iconsBox">
            <a href="https://www.facebook.com/XOXR0">
              <FontAwesomeIcon icon={faFacebookF} />
            </a>
            <a href="https://github.com/nour022">
              <FontAwesomeIcon icon={faGithub} />
            </a>
            <a href="https://www.instagram.com/_xo_xr_/">
              <FontAwesomeIcon icon={faInstagram} />
            </a>
          </div>
        </div>
        {/* <div className="button">
          <button>Let's Chat</button>
        </div> */}
      </div>
    </div>
  </section>
);

export default Contact;
